import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

const DigitalWorker = () => {
    const swiperContainerRef = useRef(null); // Reference for the Swiper container
    const swiperInstanceRef = useRef(null); // Reference to store the Swiper instance

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (swiperInstanceRef.current) {
                    if (entry.isIntersecting) {
                        swiperInstanceRef.current.autoplay.start(); // Start autoplay when in view
                    } else {
                        swiperInstanceRef.current.autoplay.stop(); // Stop autoplay when out of view
                    }
                }
            },
            { threshold: 0.5 } // Trigger when 50% of the Swiper is visible
        );

        if (swiperContainerRef.current) {
            observer.observe(swiperContainerRef.current);
        }

        return () => {
            if (swiperContainerRef.current) {
                observer.unobserve(swiperContainerRef.current);
            }
        };
    }, []);

    return (
        <div ref={swiperContainerRef} style={{ paddingBottom: '100px' }}>
            <Swiper
                pagination={{ clickable: true }}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
                slidesPerView={3}
                onSwiper={(swiper) => (swiperInstanceRef.current = swiper)} // Store the swiper instance
                className="digitalworker"
            >
                <SwiperSlide>
                    <div className="digitalworker swiper-img-div">
                        <img
                            src="https://cdn.prod.website-files.com/66fe5a1a88c73ef8f270d312%2F672cf792d7f56a2f8725c90a_Always%20Learning-poster-00001.jpg"
                            alt="Checkr"
                        />
                    </div>
                    <div className="digitalworker swiper-head-main">
                        <h1>CONTENT</h1>
                        <p>Always Evolving</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-img-div">
                        <img
                            src="https://cdn.prod.website-files.com/66fe5a1a88c73ef8f270d312/673d9a61ee1f793446f25070_Workers%20are%20self-learning-p-500.webp"
                            alt="Checkr"
                        />
                    </div>
                    <div className="swiper-head-main">
                        <h1>CONTEXT</h1>
                        <p>Compliant</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-img-div">
                        <img
                            src="https://cdn.prod.website-files.com/66fe5a1a88c73ef8f270d312%2F672cc7b548a4a34226507e20_Use%20Agentic%20Ai%20-%20revised-poster-00001.jpg"
                            alt="Checkr"
                        />
                    </div>
                    <div className="swiper-head-main">
                        <h1>REASON</h1>
                        <p>(Planning) Task Aguts</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-img-div">
                        <img
                            src="https://cdn.prod.website-files.com/66fe5a1a88c73ef8f270d312%2F672b97ee6fe4e015e89a719a_Book%20Meeting%20On%20Autopilot%281%29-poster-00001.jpg"
                            alt="Checkr"
                        />
                    </div>
                    <div className="swiper-head-main">
                        <h1>ACTIONS</h1>
                        <p>READY.</p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default DigitalWorker;
