import React, { useRef, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import logo1 from "../images/Airwallex logo.png";
import logo2 from "../images/Hasura logo.png";
import logo3 from "../images/KMS logo.png";
import logo4 from "../images/Sump logo.png";
import logo5 from "../images/checkr logo.png";
import logo6 from "../images/handshake logo.png";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Import required modules
import { Pagination, Autoplay } from 'swiper/modules';

const LogoSlider = () => {
    const swiperContainerRef = useRef(null); // Reference for the Swiper container
    const swiperInstanceRef = useRef(null); // Reference to store the Swiper instance

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (swiperInstanceRef.current) {
                    if (entry.isIntersecting) {
                        swiperInstanceRef.current.autoplay.start(); // Start autoplay when in view
                    } else {
                        swiperInstanceRef.current.autoplay.stop(); // Stop autoplay when out of view
                    }
                }
            },
            { threshold: 0.5 } // Trigger when 50% of the Swiper is visible
        );

        if (swiperContainerRef.current) {
            observer.observe(swiperContainerRef.current);
        }

        return () => {
            if (swiperContainerRef.current) {
                observer.unobserve(swiperContainerRef.current);
            }
        };
    }, []);

    return (
        <div className="logos-slider" ref={swiperContainerRef}>
            <Swiper
                pagination={{ clickable: true }}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
                slidesPerView={5}
                onSwiper={(swiper) => (swiperInstanceRef.current = swiper)} // Store the swiper instance
                className="logoSlider"
            >
                <SwiperSlide>
                    <div className="logo-box">
                        <img src={logo1} alt="Airwallex" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logo-box">
                        <img src={logo2} alt="Hasura" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logo-box">
                        <img src={logo3} alt="KMS" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logo-box">
                        <img src={logo4} alt="Sump" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logo-box">
                        <img src={logo5} alt="Checkr" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logo-box">
                        <img src={logo6} alt="Handshake" />
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default LogoSlider;
