import React, { useState, useEffect } from "react";
import DigitalWorker from "../Components/DigitalWorker";
import LogoSlider from "../Components/logo-slider";
import Footer from "../Components/Footer";
import logo from "../images/logo-original.png"
import astronout from "../images/Astronut 2(1).mp4"
import 'animate.css';

const Landing = () => {

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true); // Change background when scrolled down
            } else {
                setIsScrolled(false); // Reset background when scrolled up
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <>
            <div className="top-main">
                <div className="landing__top">
                    <p className="landing__announcement">
                        Humbot raised a $50m Series B! Read the announcement and our manifesto here.
                    </p>
                </div>
                <div
                    className={`landing__header ${isScrolled ? "landing__header--scrolled" : ""
                        }`}
                >
                    <img className={`landing__logo ${isScrolled ? "landing__logo--scrolled" : ""}`} src={logo} alt="Handshake" />
                    <div className={`landing__menu ${isScrolled ? "landing__menu--scrolled" : ""
                        }`}>

                        <a href="#">Company</a>
                        <a href="#">Careers</a>
                    </div>
                    <button className={`landing__menu-cta ${isScrolled ? "landing__menu-cta--scrolled" : ""
                        }`}>  <span>Get started</span></button>
                </div>
            </div>


            <section className="landing">
                <div className="video-container">
                    <video autoPlay muted loop className="background-video">
                        <source src={astronout} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div className="landing__content">
                    <div className="landing__title-main" >
                        <div className="landing-title-head-sec" >
                            <h5 style={{ display: 'grid' }} className="landing__title-left animate__animated animate__fadeInLeftBig">
                                A Leader
                                <span >
                                    Human Expertise
                                </span>
                            </h5>
                            <h1 className="landing__title-center animate__animated animate__fadeInLeftBig">
                                SCALES
                            </h1>
                            <h5 style={{ display: 'grid' }} className="landing__title-right animate__animated animate__fadeInLeftBig">
                                Via a Team
                                <span >
                                    via Human APIS
                                </span>

                            </h5>
                        </div>
                        <hr className="landing__divider" />
                        <div >
                            <p className="landing__subtitle">
                                AI workers for Expert outcomes
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <LogoSlider />
            <section className="box-section">
                <div className="box-container">
                    <div className="digital-head-div">
                        <h1>
                            Leverage Your Expertise & <br /> Organization
                        </h1>
                        <p>
                            Intelligent, enterprise-ready, and seamlessly embedded in your operations—digital <br /> workers bring advanced AI technology to your team, scaling effortlessly to drive outcomes <br /> and push productivity.
                        </p>
                        <button className="landing__ctaa">
                            <span>Action</span>
                        </button>
                    </div>

                    <div className="tags-slider" style={{ width: '100%' }}>
                        <div className="tags-container">
                            {[
                                'Increase pipeline',
                                'Stay ahead of competition',
                                'Refine ICP',
                                'Lead qualification on autopilot',
                                'Boost Conversion Rates',
                                'Operational efficiency',
                                'Stay ahead of competition',
                                'Increase pipeline',
                            ].map((tag, index) => (
                                <span className="tag" key={`first-${index}`}>
                                    {tag}
                                </span>
                            ))}
                        </div>
                        <div className="tags-container">
                            {[
                                'Increase pipeline',
                                'Stay ahead of competition',
                                'Refine ICP',
                                'Lead qualification on autopilot',
                                'Boost Conversion Rates',
                                'Operational efficiency',
                                'Boost Conversion Rates',
                                'Stay ahead of competition',
                            ].map((tag, index) => (
                                <span className="tag" key={`second-${index}`}>
                                    {tag}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="tags-slider2" style={{ width: '100%' }}>
                        <div className="tags-container2">
                            {[
                                'Increase pipeline',
                                'Stay ahead of competition',
                                'Refine ICP',
                                'Lead qualification on autopilot',
                                'Boost Conversion Rates',
                                'Operational efficiency',
                                'Stay ahead of competition',
                                'Increase pipeline',
                            ].map((tag, index) => (
                                <span className="tag" key={`first-${index}`}>
                                    {tag}
                                </span>
                            ))}
                        </div>
                        <div className="tags-container2">
                            {[
                                'Increase pipeline',
                                'Stay ahead of competition',
                                'Refine ICP',
                                'Lead qualification on autopilot',
                                'Boost Conversion Rates',
                                'Operational efficiency',
                                'Boost Conversion Rates',
                                'Stay ahead of competition',
                            ].map((tag, index) => (
                                <span className="tag" key={`second-${index}`}>
                                    {tag}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="tags-slider3" style={{ width: '100%' }}>
                        <div className="tags-container3">
                            {[
                                'Increase pipeline',
                                'Stay ahead of competition',
                                'Refine ICP',
                                'Lead qualification on autopilot',
                                'Boost Conversion Rates',
                                'Operational efficiency',
                                'Stay ahead of competition',
                                'Increase pipeline',
                            ].map((tag, index) => (
                                <span className="tag" key={`first-${index}`}>
                                    {tag}
                                </span>
                            ))}
                        </div>
                        <div className="tags-container3">
                            {[
                                'Increase pipeline',
                                'Stay ahead of competition',
                                'Refine ICP',
                                'Lead qualification on autopilot',
                                'Boost Conversion Rates',
                                'Operational efficiency',
                                'Boost Conversion Rates',
                                'Stay ahead of competition',
                            ].map((tag, index) => (
                                <span className="tag" key={`second-${index}`}>
                                    {tag}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="digital-main">
                    <div className="digital-head-div" >
                        <h1>
                            Your AI Workforce for Expert Outcomes, <br />  Your Tools, Your Guardian
                        </h1>
                        <p>
                            Intelligent, enterprise-ready, and seamlessly embedded in your operations—digital <br /> workers bring advanced AI technology to your team, scaling effortlessly to drive outcomes <br /> and push productivity.
                        </p>
                        <button className="landing__ctaa">
                            <span>Action</span>
                        </button>
                    </div>
                    <div>
                        <DigitalWorker />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default Landing;
