import React from "react";
import linkdin from "../images/linkdin.png"
import twitter from "../images/twitter.png"
import logo from "../images/logo-original.png"


const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-contain">
                <div className="left-main">
                    <div className="footer-logo-main">
                        <img src={logo} alt="Checkr" />
                    </div>

                    <div className="footer-quick-links">
                        <h1>
                            Quicklinks
                        </h1>
                        <p>
                            Careers
                        </p>
                        <p>
                            Company
                        </p>
                    </div>
                </div>
                <div className="footer-right">
                    <div className="footer-address">
                        <h4>HUMBOTS are Deflationary</h4>
                        <p> Humbots ACT 24/7</p>
                    </div>
                    <div className="footer-address">
                        <h4>TEAM.
                            why work with Us.</h4>
                        <p>We have setup Humbat with ar to SCALE Human  Expertise</p>
                    </div>
                    <div className="social-icon-main">
                        <div className="social-icon">
                            <img src={linkdin} alt="Checkr" />
                            <img src={twitter} alt="Checkr" />
                        </div>

                        <div className="social-icon2">
                            <p>
                                © All rights reserved 2024 Humbot  AI .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
